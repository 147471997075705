import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import PersonSearch from "@mui/icons-material/PersonSearch";
import Toolbar from "@mui/material/Toolbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { ExpandMore } from "@mui/icons-material";
import { styled } from "@mui/system";
import { ethers, formatEther, isAddress } from "ethers";
import Web3Modal from "web3modal";
import { useAppContext } from "./AppContext";
import "./App.css";
import notifiLogo from "./notifi_logo_no_bg.svg";

const SERVER_URL = "https://api.etherdox.xyz";
// const SERVER_URL = "http://localhost:8080";

const { BrowserProvider } = ethers;

const StyledAppBar = styled(AppBar)({
  backgroundColor: "rgba(0, 0, 0, 0.3)", // Make AppBar transparent
  position: "absolute",
});

const StyledTypographyTitle = styled(Typography)({
  fontWeight: "bold",
  color: "#FFFFFF",
});

const StyledTypographyUserAddress = styled(Typography)({
  fontWeight: "bold",
  fontSize: "1rem",
  color: "#FFFFFF",
  marginRight: "1rem",
  display: "inline-block",
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
  position: "relative",
  top: "50%",
  transform: "translateY(50%)",
  maxWidth: "calc(100vw - 400px)",
});

const StyledConnectButton = styled(Button)({
  "&:hover": {
    backgroundColor: "#7B68EE",
  },
  // make the button a complementary purple to the background
  backgroundColor: "#7B68EE",
  color: "#FFFFFF",
  fontWeight: "bold",
  display: "inline-block",
});

const StyledToolbar = styled(Toolbar)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  maxWidth: "100%",
});

const StyledContainer = styled(Container)({
  minHeight: "100vh",
  display: "flex",
  minWidth: "100%",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  background: "linear-gradient(135deg, #7B68EE, #8B008B)",
  paddingTop: "6rem",
  paddingBottom: "4rem",
  width: "100%",
});

const StyledForm = styled("form")({
  display: "flex",
  gap: 2,
  background: "rgba(255, 255, 255, 0)",
  "& .MuiTextField-root": {
    color: "#FFFFFF",
  },
  borderRadius: "10px",
  padding: "1rem",
  width: "100%",
  maxWidth: "800px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FFFFFF",
    },
    "&:hover fieldset": {
      borderColor: "#FFFFFF",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#FFFFFF",
    },
  },
  "&::-webkit-input-placeholder": {
    zIndex: "2",
  },
  position: "relative",
  // flexWrap: "wrap",
  justifyContent: "center",
});

const StyledButton = styled(Button)({
  "&:hover": {
    backgroundColor: "#7B68EE",
  },
  // make the button a complementary purple to the background
  backgroundColor: "#7B68EE",
  color: "#FFFFFF",
  fontWeight: "bold",
});

const StyledBoxRelatedAddresses = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 2,
  marginTop: 4,
  width: "100%",
  maxWidth: "800px",
});

const StyledExplanationText = styled(Typography)({
  color: "#FFFFFF",
  textAlign: "center",
  marginBottom: "1rem",
  fontWeight: "bold",
});

const StyledTableContainer = styled(TableContainer)({
  width: "100%",
  maxWidth: "800px",
});

const StyledTableCell = styled(TableCell)({
  color: "#FFFFFF",
  fontWeight: "bold",
  maxWidth: "25%",
  overflowX: "auto",
  whiteSpace: "nowrap",
  textAlign: "center",
  padding: "0.5rem",
});

function App() {
  const [address, setAddress] = useState("");
  const [relatedAddresses, setRelatedAddresses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingEns, setLoadingEns] = useState(false);
  const [doxxedText, setDoxxedText] = useState(null);
  const [expandedRowIndex, setExpandedRowIndex] = useState(-1);

  const {
    userAddress,
    setUserAddress,
    userEns,
    setUserEns,
    provider,
    setProvider,
    web3Modal,
    setWeb3Modal,
  } = useAppContext();

  useEffect(() => {
    if (address && userAddress) {
      const url = new URL(window.location);
      url.searchParams.set("address", address);
      window.history.pushState(null, "", url);
    } else {
      const url = new URL(window.location);
      url.searchParams.delete("address");
      window.history.pushState(null, "", url);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address]);

  useEffect(() => {
    const onPopState = async (event) => {
      const url = new URL(window.location);
      const initialAddress = url.searchParams.get("address");

      if (initialAddress) {
        handleAddressClick(initialAddress);
      }
    };
    window.addEventListener("popstate", onPopState);
    return () => {
      window.removeEventListener("popstate", onPopState);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const getUserAddress = async (web3Modal) => {
      const instance = await web3Modal.connect();
      const provider = new BrowserProvider(instance);
      setProvider(provider);
      const signer = await provider.getSigner();
      setUserAddress(signer.address);
      const ens = await provider.lookupAddress(signer.address);
      setUserEns(ens);

      const url = new URL(window.location);
      const initialAddress = url.searchParams.get("address");
      if (initialAddress && !address) {
        setAddress(initialAddress);
      } else if (!address) {
        setAddress(signer.address);
      }
    };

    if (web3Modal && !provider) {
      getUserAddress(web3Modal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [web3Modal]);

  const connectWallet = async (e) => {
    e.preventDefault();
    if (!web3Modal) {
      const providerOptions = {};
      const web3ModalInstance = new Web3Modal({
        network: "mainnet",
        cacheProvider: true,
        providerOptions,
      });
      setWeb3Modal(web3ModalInstance);
    } else {
      disconnectWallet();
    }
  };

  const disconnectWallet = () => {
    web3Modal.clearCachedProvider();
    setWeb3Modal(null);
    setUserAddress(null);
    setUserEns(null);
    setProvider(null);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleExpandRow = (index) => {
    setExpandedRowIndex(expandedRowIndex === index ? -1 : index);
  };

  const clearAddresses = (e) => {
    e.preventDefault();
    setRelatedAddresses([]);
    setAddress("");
    setDoxxedText(null);
  };

  const resolveEnsName = async (name) => {
    if (provider) {
      const resolvedAddress = await provider.resolveName(name);
      return resolvedAddress;
    }
    return null;
  };

  const addEnsNames = async (rAddresses) => {
    setLoadingEns(true);
    const relatedAddressesWithEns = rAddresses.map(async (rAddress) => {
      let ensName;
      if (provider) {
        ensName = await provider.lookupAddress(rAddress.address);
      }
      return { ...rAddress, ensName };
    });
    const resolvedRelatedAddresses = await Promise.all(relatedAddressesWithEns);
    setRelatedAddresses(resolvedRelatedAddresses);
    setLoadingEns(false);
  };

  const search = async (inputAddress) => {
    try {
      console.log(inputAddress);
      if (!inputAddress) {
        setDoxxedText(
          `Invalid Ethereum address submitted. ${
            !userAddress ? "Connect wallet to resolve ENS names." : ""
          }`
        );
        throw new Error("Invalid Ethereum address submitted");
      }
      const response = await fetch(`${SERVER_URL}/api/analyze`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ address: inputAddress }),
      });

      if (!response.ok) {
        throw new Error("Error fetching related addresses");
      }

      const data = await response.json();
      let rAddresses = (data.relatedAddresses || []).filter((r) => r.address);
      if (rAddresses.length === 0) {
        setDoxxedText("No related addresses found.");
      } else {
        setDoxxedText(null);
      }

      addEnsNames(rAddresses);

      // setRelatedAddresses(rAddresses);
    } catch (err) {
      console.error("Error:", err);
      // Handle error gracefully, e.g., show an error message to the user
    }

    setLoading(false);
  };

  const handleAddressClick = async (inputAddress) => {
    setLoading(true);
    setRelatedAddresses([]);
    setAddress(inputAddress);

    return search(inputAddress);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setRelatedAddresses([]);

    let inputAddress = address;

    const resolvedAddress = await resolveEnsName(address);
    if (resolvedAddress) {
      inputAddress = resolvedAddress;
      setAddress(inputAddress);
    } else if (!isAddress(inputAddress)) {
      inputAddress = null;
    }
    return search(inputAddress);
  };

  return (
    <>
      <StyledAppBar position="static">
        <StyledToolbar>
          <StyledTypographyTitle
            variant="h6"
            component="div"
            onClick={clearAddresses}
            style={{ cursor: "pointer" }}
          >
            EtherDox
          </StyledTypographyTitle>
          <div>
            {(userEns || userAddress) && (
              <StyledTypographyUserAddress variant="h6" component="div">
                {userEns || userAddress}
              </StyledTypographyUserAddress>
            )}
            <StyledConnectButton variant="contained" onClick={connectWallet}>
              {(userAddress && "Disconnet Wallet") || "Connect Wallet"}
            </StyledConnectButton>
          </div>
        </StyledToolbar>
      </StyledAppBar>
      <StyledContainer>
        <StyledExplanationText>
          Enter an Ethereum address to find other addresses owned by the same
          user
        </StyledExplanationText>
        <StyledForm onSubmit={handleSubmit}>
          <TextField
            label="Ethereum Address"
            autoComplete="off"
            // label color should be white
            InputLabelProps={{
              style: { color: "#FFFFFF" },
            }}
            value={address}
            onChange={handleAddressChange}
            fullWidth
            InputProps={{
              style: { color: "#FFFFFF" },
            }}
            disabled={loadingEns}
          />

          <StyledButton variant="contained" type="submit" disabled={loadingEns}>
            Search
          </StyledButton>
        </StyledForm>
        {loading && <CircularProgress />}
        {relatedAddresses.length > 0 && (
          <StyledBoxRelatedAddresses>
            <StyledTypographyTitle
              variant="h7"
              component="div"
              style={{ paddingBottom: "2rem", paddingTop: "1rem" }}
            >
              <Link
                href={`https://www.notifi.xyz/profile/${address}`}
                target="_blank"
                rel="noopener noreferrer"
                color="inherit"
              >
                See Notifi profile for more details on the searched address
              </Link>
            </StyledTypographyTitle>
            <StyledTableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Address</StyledTableCell>
                    <StyledTableCell>Score</StyledTableCell>
                    <StyledTableCell>Notifi</StyledTableCell>
                    <StyledTableCell>Details</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {relatedAddresses.map((relatedAddress, index) => (
                    <>
                      <TableRow key={index}>
                        <StyledTableCell
                          // overflow text ellipsis
                          style={{
                            textAlign: "left",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            maxWidth: "10%",
                          }}
                        >
                          <Tooltip title="Search this address">
                            <IconButton
                              onClick={() =>
                                handleAddressClick(relatedAddress.address)
                              }
                              size="small"
                              style={{ color: "#FFFFFF" }}
                              disabled={loadingEns}
                            >
                              <PersonSearch />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Go to Etherscan">
                            <Link
                              href={`https://etherscan.io/address/${relatedAddress.address}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              color="inherit"
                              underline="hover"
                            >
                              <div
                                style={{
                                  textOverflow: "ellipsis",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  display: "inline-block",
                                  verticalAlign: "middle",
                                }}
                              >
                                {relatedAddress.ensName ||
                                  relatedAddress.address}
                              </div>
                            </Link>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "right" }}>
                          {parseFloat(relatedAddress.probabilityScore).toFixed(
                            2
                          )}
                        </StyledTableCell>
                        <StyledTableCell style={{ textAlign: "center" }}>
                          <Tooltip title="Go to Notifi profile">
                            <IconButton
                              onClick={() =>
                                // send user to https://www.notifi.xyz/profile/{relatedAddress.address} in a new tab
                                window.open(
                                  `https://www.notifi.xyz/profile/${relatedAddress.address}`,
                                  "_blank"
                                )
                              }
                              size="small"
                              style={{
                                color: "#FFFFFF",
                              }}
                              disabled={loadingEns}
                            >
                              <img
                                src={notifiLogo}
                                alt="notifi logo"
                                style={{ width: "22px", filter: "invert(1)" }}
                              />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                        <StyledTableCell>
                          <Tooltip title="See related transactions">
                            <IconButton
                              onClick={() => handleExpandRow(index)}
                              size="small"
                              // color white
                              style={{ color: "#FFFFFF" }}
                            >
                              <ExpandMore
                                style={{
                                  transform:
                                    expandedRowIndex === index
                                      ? "rotate(180deg)"
                                      : "",
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </StyledTableCell>
                      </TableRow>
                      <TableRow key={`${index}_2`}>
                        <TableCell
                          style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            borderBottom: "none",
                          }}
                          colSpan={6}
                        >
                          <Collapse
                            in={expandedRowIndex === index}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box margin={1}>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <StyledTableCell>
                                      Transaction Hash
                                    </StyledTableCell>
                                    <StyledTableCell>ETH Value</StyledTableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {relatedAddress.funderTransactions.map(
                                    (transaction, txnIndex) => (
                                      <TableRow key={txnIndex}>
                                        <StyledTableCell
                                          style={{ textAlign: "left" }}
                                        >
                                          <Link
                                            href={`https://etherscan.io/tx/${transaction.hash}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            color="inherit"
                                            underline="hover"
                                          >
                                            {transaction.hash}
                                          </Link>
                                        </StyledTableCell>
                                        <StyledTableCell
                                          style={{ textAlign: "right" }}
                                        >
                                          {parseFloat(
                                            formatEther(transaction.value)
                                          ).toFixed(2)}{" "}
                                          ETH
                                        </StyledTableCell>
                                      </TableRow>
                                    )
                                  )}
                                </TableBody>
                              </Table>
                            </Box>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </>
                  ))}
                </TableBody>
              </Table>
            </StyledTableContainer>
          </StyledBoxRelatedAddresses>
        )}
        {!relatedAddresses.length && doxxedText && (
          <div>
            {isAddress(address) && (
              <StyledTypographyTitle
                variant="h7"
                component="div"
                style={{
                  paddingBottom: "1rem",
                  paddingTop: "1rem",
                  textAlign: "center",
                }}
              >
                <Link
                  href={`https://www.notifi.xyz/profile/${address}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  color="inherit"
                >
                  Check the Notifi profile for this address for more details
                </Link>
              </StyledTypographyTitle>
            )}
            <StyledTypographyTitle
              variant="h6"
              component="div"
              style={{ padding: "2rem", textAlign: "center" }}
            >
              {doxxedText}
            </StyledTypographyTitle>
          </div>
        )}
      </StyledContainer>
    </>
  );
}

export default App;
